// Helpers
import { objectGet } from "../helpers/Objects";
import { expectNumber, expectString } from "../helpers/Expect";

// -------------------------------------------------------------------- Function

/**
 * Convert Mpbox features to an address object
 *
 * @param  {Object} feature - Mapbox feature
 *
 * @return {Object} - Normalized address object
 */
export function mapboxFeatureToAddress( feature )
{
  const location = {
    name: null,
    busStopName: null,
    street: null,
    streetNumber: null,
    postalCode: null,
    gpsLatitude: null,
    gpsLongitude: null,
    place: null
  };

  if( feature.place_type.indexOf( "address") !== -1 )
  {
    location.streetNumber = feature.address;
    location.street = objectGet( feature, "text" );
  }
  else if( feature.place_type.indexOf( "postcode") !== -1 )
  {
    location.postalCode = objectGet( feature, "text" );
  }
  else if( feature.place_type.indexOf( "place") !== -1 )
  {
    location.place = objectGet( feature, "text" );
  }
  else if( feature.place_type.indexOf( "poi") !== -1 )
  {
    location.name = objectGet( feature, "text" );
    location.street = objectGet( feature, "properties.address" );
  }

  for( const item of feature.context )
  {
    if( item.id.indexOf( "postcode" ) !== -1 )
    {
      location.postalCode = item.text;
      continue;
    }

    if( item.id.indexOf( "neighborhood" ) !== -1 )
    {
      location.neighborhood = item.text;
      continue;
    }

    if( item.id.indexOf( "place" ) !== -1 )
    {
      location.place = item.text;
      continue;
    }
  }

  const lng = objectGet( feature, "center.0" );
  const lat = objectGet( feature, "center.1" );

  expectNumber( lat, "Invalid [lat]" );
  expectNumber( lng, "Invalid [lng]" );

  location.gpsLatitude = lat.toString();
  location.gpsLongitude = lng.toString();

  return location;
}

// -------------------------------------------------------------------- Function

/**
 * Get search suggestions from Mapbox as features
 *
 * @param  {String} text
 *
 * @return {Array} - Array with suggestion features
 */
export async function getSearchSuggestions( text )
{
  expectString( text, "Invalid [suggestions]" );

  const publicToken =
    "sk.eyJ1IjoiYmVzc3ktc29sdXRpb25zIiwiYSI6ImNrbHo0bGtlaTFlajUyb213OHFnbjVyOWUifQ.9YYEZaXK0RQ-UMlgY7f72g";

  const host = "https://api.mapbox.com";
  const path = `/geocoding/v5/mapbox.places/${text}.json`;
  let params = `?access_token=${publicToken}&autocomplete=true&language=nl`;

  const url = `${host}${path}${params}`;

  return fetch( url, {
     method: 'get',
     headers: {
       'Content-Type': 'application/json'
     }
   })
  .then(data => data.json());
}
